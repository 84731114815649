import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Footer(){

    const { t } = useTranslation();

    return (
        <div id="footer-container">
            
            <img id="logo-footer" src='./images/logo.png' />
            
            <h2>{t('poweredBy')}&nbsp;
                <a href="https://conferience.com" target="_blank">
                <img className="conferience-logo" src="./images/conferienceWhite.jpg" alt="conferience-logo" />
                </a>
                
            </h2>
            <div className='links'>
                <a href='https://conferience.com/terms' target="_blank">{t('terms')}</a>
                <a href='https://conferience.com/privacy' target="_blank">{t('privacy')}</a>
            </div>
            
        </div>
    )
}