import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

export default function About(){

    const { t } = useTranslation();

    const [showFAQ, setshowFAQ] = useState(false);

    function showHide(){
        setshowFAQ(prevShowP => !prevShowP);
    }

    return (
        <div className='contact-demo' id="contact-demo">
            <h2>{t('formTitle')}</h2>
            <h3>{t('formSubtitle')} <a href="https://conferience.com/" >info@conferience.com</a></h3>
            <div className='faq-container'>
            <button id="faq" className="faq-button" onClick={showHide}>FAQ</button>
            <div className='faq' style={{display:  showFAQ ? 'block' : 'none' }}>
                <div className='question'>
                    {t('q1')}
                </div>
                <div className='answer'>
                    {t('a1')}
                </div>
                <div className='question'>
                    {t('q2')}
                </div>
                <div className='answer'>
                    {t('a2')}
                </div>
                <div className='question'>
                    {t('q3')}
                </div>
                <div className='answer'>
                    {t('a3')}
                </div>
                <div className='question'>
                    {t('q4')}
                </div>
                <div className='answer'>
                    {t('a4')}
                </div>
                <div className='question'>
                    {t('q5')}
                </div>
                <div className='answer'>
                    {t('a5')}
                </div>
            </div>
            </div>
        </div>
        
    )


}