import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import i18n from 'i18next';

export default function Testimonials() {

	const { t } = useTranslation();



	return (
		<>
			<h1 style={{marginTop: '40px', marginBottom: '30px'}}>{t('testimonialsTitle')}</h1>
			<div id="testimonials-container">
				<div style={{ padding: '20px' }}>
					<div style={{maxWidth: '800px', textAlign: 'center', margin: '0 auto'}}>
						<a href="https://ranch.gr" target="_blank">
							<img src="https://ranch.gr/themes/custom/rocket_path_theme/logo.svg" alt='sportcampkids' style={{ width: '200px'}} />
						</a>
						<p>
							{t('theranchTestimonialText')}
						</p>
						<p>{t('theranchTestimonialClient')}</p>
						<p>
							<a href="https://ranch.gr" target="_blank">
								The Ranch
							</a>
						</p>
					</div>
				</div>
				<div style={{ padding: '20px' }}>
					<div style={{maxWidth: '800px', textAlign: 'center', margin: '0 auto'}}>
						<a href="https://www.sportcampkids.gr" target="_blank">
							<img src="./images/sportcamp-logo.png" alt='sportcampkids' style={{ width: '200px'}} />
						</a>
						<p>
							{t('sportcampTestimonialText')}
						</p>
						<p>{t('sportcampTestimonialClient')}</p>
						<p>
							<a href="https://www.sportcampkids.gr" target="_blank">
								SPORTCAMP
							</a>
						</p>
					</div>
				</div>
			</div>
		</>
		
	)
}