import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

export default function Description() {

    const { t } = useTranslation();

    const [showP, setShowP] = useState(false);

    function moreLess() {
        setShowP(prevShowP => !prevShowP);
    }



    return (
        <div id="description-container">

            <h2 style={{ color: "#b84739" }}>{t('theFirstApp')}</h2>

            <h1>{t('theProductTitle')}</h1>



            <div className='bullet-container'>
                <div className='bullet-points'>
                    <img src="./images/bullet.png" alt="bullet" />
                    {t('bullet1')}
                </div>
                <div className='bullet-points'>
                    <img src="./images/bullet.png" alt="bullet" />
                    {t('bullet2')}
                </div>
                <div className='bullet-points'>
                    <img src="./images/bullet.png" alt="bullet" />
                    {t('bullet3')}
                </div>
                <div className='bullet-points'>
                    <img src="./images/bullet.png" alt="bullet" />
                    {t('bullet4')}
                </div>
            </div>

            <div style={{ maxWidth: '1000px', padding: '20px' }}>
                {i18n.language === "gr" &&
                    <video controls style={{ maxWidth: '1000px' }}>
                        <source src="./videos/Xcamp-GR.mp4" type="video/mp4" autoplay />
                        Your browser does not support HTML video.
                    </video>
                }
                {i18n.language === "en" &&
                    <video controls style={{ width: '100%' }}>
                        <source src="./videos/Xcamp-ENG.mp4" type="video/mp4" autoplay />
                        Your browser does not support HTML video.
                    </video>
                }
                {i18n.language === "es" &&
                    <video controls style={{ width: '100%' }}>
                        <source src="./videos/Xcamp-ES.mp4" type="video/mp4" autoplay />
                        Your browser does not support HTML video.
                    </video>
                }
                {i18n.language === "ru" &&
                    <video controls style={{ width: '100%' }}>
                        <source src="./videos/Xcamp-RU.mp4" type="video/mp4" autoplay />
                        Your browser does not support HTML video.
                    </video>
                }
            </div>

            <div style={{ maxWidth: '1000px', padding: '20px' }}>
                <div className='smallTitles'>{t('theGoalTitle')}</div>
                <div className='lines'>{t('theGoalLine1')}</div>
                <div className='lines'>{t('theGoalLine2')}</div>
                <div className='lines'>{t('theGoalLine3')}</div>
                <div className='lines'>{t('theGoalLine4')}</div>

                <div className='smallTitles'>{t('theUseTitle')}</div>
                <div className='lines'>{t('theUse')}</div>
            </div>

            <div id="text">


                <span style={{ display: showP ? 'block' : 'none' }}>
                    {t('theProductFirstLine')}
                    <br />
                    {t('theProduct')}
                    <br /><br />
                    <span id="text2">{t('theProduct2')}</span>
                </span>
                <button className="button" onClick={moreLess}>{showP ? t('less') : t('more')}</button>

            </div>

        </div>
    )
}