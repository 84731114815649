import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

export default function Main(){

    const { t } = useTranslation();

    return (
        <div id="main-container">
            <div id="img-container">
                <div id="slogan">{t('catchphrase')}</div>
            </div>
        </div>
    )
}