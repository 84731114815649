import React, {useState, useEffect} from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

export default function Navbar(){

    const [dropdown, setDropdown] = useState(false);
    function showDropdown(){
        setDropdown(prevDisplay => !prevDisplay);
    }

    function hideDropdown(){
        setDropdown(false);
    }

    const { t } = useTranslation();

    const faqScroll = ()=>{
        const faq = document.getElementById('faq');
        faq.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    };
    
    const featuresScroll = ()=>{
        const features = document.getElementById('features-container');
        features.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    };

    const aboutScroll = ()=>{
        const about = document.getElementById('description-container');
        about.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    };

    const contactScroll = ()=>{
        const contact = document.getElementById('contact-demo');
        contact.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    };

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
        hideDropdown();
    }


    // Sticky Nav

    const [isNavbarShrunk, setIsNavbarShrunk] = useState(false);

    const [toggleNav, setToggleNav] = useState(false);

    const [toggleAside, setToggleAside] = useState(false);

    

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        console.log(scrollTop);

        // Adjust the value (e.g., 100) based on when you want the navbar to shrink
        setIsNavbarShrunk(scrollTop > 100);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [])


    const navStyle = {
        zIndex: 20,
        height: isNavbarShrunk ? '55px' : '',
        top: 0,
        backgroundColor: isNavbarShrunk ? 'white' : ''
    }

    return (
        

        <nav style={navStyle}>
            <img id="logo" style={{width: isNavbarShrunk ? '170px' : '200px',
        top: isNavbarShrunk ? '-5px' : '-20px'
        }} src='./images/logo.png' />
            <div id="nav-container">
                <ul>
                    <li onClick={aboutScroll}>{t('about')}</li>
                    <li onClick={featuresScroll}>{t('features')}</li>
                    <li onClick={faqScroll}>FAQ</li>
                    <li onClick={contactScroll}>{t('contact')}</li>
                    <li>
                        <div>
                            <span>
                                { i18n.language === "gr" &&
                                    <img id="earth-icon" src='./images/greece-flag.png' alt='Greece' style={{verticalAlign: 'bottom'}} /> 
                                }
                                { i18n.language === "en" &&
                                    <img id="earth-icon" src='./images/united-kingdom-flag.png' alt='United Kingdom' style={{verticalAlign: 'bottom'}} /> 
                                }  
                                { i18n.language === "es" &&
                                    <img id="earth-icon" src='./images/spain-flag.png' alt='Spain' style={{verticalAlign: 'bottom'}} /> 
                                }
                                { i18n.language === "ru" &&
                                    <img id="earth-icon" src='./images/russia-flag.png' alt='Spain' style={{verticalAlign: 'bottom'}} /> 
                                }
                            </span>
                            
                            <select id="languages" onChange={changeLanguage}>
                                <option value="en">English </option>
                                <option value="gr">Ελληνικά</option>
                                <option value="es">Spanish</option>
                                <option value="ru">Russian</option>
                            </select>
                        </div>
                    </li>

                </ul>
            </div>

            <div id="mobile-nav-container">
                <img id="menu" src='./images/menu.png' onClick={showDropdown} />
                
                <div id="dropdown-menu" style={{display:  dropdown ? 'block' : 'none' }}>
                    <div>
                        { i18n.language === "gr" &&
                            <img id="earth-icon" src='./images/greece-flag.png' alt='Greece' style={{verticalAlign: 'bottom'}} /> 
                        }
                        { i18n.language === "en" &&
                            <img id="earth-icon" src='./images/united-kingdom-flag.png' alt='United Kingdom' style={{verticalAlign: 'bottom'}} /> 
                        }  
                        { i18n.language === "es" &&
                            <img id="earth-icon" src='./images/spain-flag.png' alt='Spain' style={{verticalAlign: 'bottom'}} /> 
                        }  
                        <select id="languages" onChange={changeLanguage}>
                            <option value="en">English </option>
                            <option value="gr">Ελληνικά</option>
                            <option value="es">Spanish</option>
                        </select>
                    </div>
                    
                    <div onClick={aboutScroll}>{t('about')}</div>
                        <div onClick={featuresScroll}>{t('features')}</div>
                        <div onClick={faqScroll}>FAQ</div>
                        <div onClick={contactScroll}>{t('contact')}</div>
                    </div>
                    
                
                </div>
            
        </nav>
        
        
    )
}