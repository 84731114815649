import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import i18n from 'i18next';

export default function Features(){

    const { t } = useTranslation();

    const screens = [
        {
            id: 0,
            filename: 'bell3.jpg',
            caption: t('feature0Title')
        },
        {
            id: 1,
            filename: 'prioritize.png',
            caption: t('feature1Title')
        },
        {
            id: 2,
            filename: 'status2.png',
            caption: t('feature2Title')
        },
        {
            id: 3,
            filename: 'categorize.png',
            caption: t('feature3Title')
        },
        {
            id: 4,
            filename: 'search.png',
            caption: t('feature4Title')
        },
        {
            id: 5,
            filename: 'records.png',
            caption: t('feature5Title')
        },
        {
            id: 6,
            filename: 'team.png',
            caption: t('feature6Title')
        },
        {
            id: 7,
            filename: 'excel.png',
            caption: t('feature7Title')
        },
        {
            id: 8,
            filename: 'missing.png',
            caption: t('feature8Title')
        },
        
      ]

    

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const [modalImage, setModalImage] = useState([]);

    const handleShow = (e, index) => {
        setCurrentImageIndex(index);
        console.log(index);
        setShow(true);
        setModalImage();
    
    }

    const showPreviousImage = () => {
        console.log(screens.length, currentImageIndex);
        setCurrentImageIndex((previousIndex) =>{
            if(previousIndex <= 0){
               return screens.length - 1
            }
            return previousIndex - 1
        })
    }

    const showNextImage = () => {
        console.log(screens.length, currentImageIndex);
        setCurrentImageIndex((previousIndex) =>{
            if(previousIndex >= screens.length -1){
                return 0
             }
            return previousIndex + 1
        })
    }

    return (
        <div id="features-container">
            <h1>{t('featuresTitle')}</h1>
            
            <div id="cols-container">
                <div className='col-1'>
                    <img 
                        id='feature-image' 
                        src="./images/bell3.jpg" 
                        alt="feature" 
                        onClick={(e) => {
                            handleShow(e, 0)
                        }}
                    />
                    <h2 className='feature-title'>{t('feature0Title')}</h2>
                    <div className='feature-description'>
                    {t('feature0')}
                    </div>
                </div>
                <div className='col-1'>
                    <img 
                        id='feature-image' 
                        src={"./images/screenshots/" + i18n.language + "/prioritize.png"} 
                        alt="feature" 
                        onClick={(e) => {
                            handleShow(e, 1)
                        }}
                    />
                    <h2 className='feature-title'>{t('feature1Title')}</h2>
                    <div className='feature-description'>
                    {t('feature1')}
                    </div>
                </div>
                <div className='col-2'>
                    <img 
                        id='feature-image' 
                        src={"./images/screenshots/" + i18n.language + "/status2.png"} 
                        alt="feature" 
                        onClick={(e) => {
                            handleShow(e, 2)
                        }}
                    />
                    <h2 className='feature-title'>{t('feature2Title')}</h2>
                    <div className='feature-description'>{t('feature2')}</div>
                </div>
                <div className='col-3'>
                    <img 
                        id='feature-image' 
                        src={"./images/screenshots/" + i18n.language + "/categorize.png"} 
                        alt="feature" 
                        onClick={(e) => {
                            handleShow(e, 3)
                        }}
                    />
                    <h2 className='feature-title'>{t('feature3Title')}</h2>
                    <div className='feature-description'>{t('feature3')}</div>
                </div>
                <div className='col-3'>
                    <img 
                        id='feature-image' 
                        src={"./images/screenshots/" + i18n.language + "/search.png"} 
                        alt="feature" 
                        onClick={(e) => {
                            handleShow(e, 4)
                        }}
                    />
                    <h2 className='feature-title'>{t('feature4Title')}</h2>
                    <div className='feature-description'>{t('feature4')}</div>
                </div>
                <div className='col-3'>
                    <img 
                        id='feature-image' 
                        src={"./images/screenshots/" + i18n.language + "/records.png"} 
                        alt="feature" 
                        onClick={(e) => {
                            handleShow(e, 5)
                        }}
                    />
                    <h2 className='feature-title'>{t('feature5Title')}</h2>
                    <div className='feature-description'>{t('feature5')}</div>
                </div>
                <div className='col-3'>
                    <img
                        id='feature-image' 
                        src={"./images/screenshots/" + i18n.language + "/team.png"} 
                        alt="feature" 
                        onClick={(e) => {
                            handleShow(e, 6)
                        }}
                    />
                    <h2 className='feature-title'>{t('feature6Title')}</h2>
                    <div className='feature-description'>{t('feature6')}</div>
                </div>

                <div className='col-3'>
                    <img
                        id='feature-image' 
                        src={"./images/screenshots/" + i18n.language + "/missing.png"} 
                        alt="feature" 
                        onClick={(e) => {
                            handleShow(e, 8)
                        }}
                    />
                    <h2 className='feature-title'>{t('feature8Title')}</h2>
                    <div className='feature-description'>{t('feature8')}</div>
                </div>

                <div className='col-3'>
                    <img
                    style={{padding: '30px'}}
                        id='feature-image' 
                        src={"./images/excel.png"} 
                        alt="feature" 
                        onClick={(e) => {
                            handleShow(e, 7)
                        }}
                    />
                    <h2 className='feature-title'>{t('feature7Title')}</h2>
                    <div className='feature-description'>{t('feature7')}</div>
                </div>

                
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >

                <Modal.Body className='modal__body'>
                    <img id='x' onClick={handleClose} src='./images/x.png' alt='close' />
                    <div className='container'>
                        <img className='modal-image' src={"./images/screenshots/" + i18n.language + "/"  + screens[currentImageIndex].filename} alt='modal-image' />
                        <h2 className='feature-title'>{screens[currentImageIndex].caption}</h2>
                        <img id='left-arrow' onClick={showPreviousImage} src='./images/arrowLeft.png' alt='left' /> 
                        <img id='right-arrow' onClick={showNextImage} src='./images/arrowRight.png' alt='right' />  
                    </div>
                    
                        
                </Modal.Body>
                
            </Modal>
            
        </div>
    )
}