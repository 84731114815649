import './App.css';
import Navbar from './components/Navbar';
import Main from './components/Main';
import Description from './components/Description';
import Features from './components/Features';
import About from './components/About';
import Footer from './components/Footer';
import React, { Suspense, useState, useTransition, useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import Testimonials from './components/Testimonials';


const transGr = {
  features: "Χαρακτηριστικά",
  theFirstApp: "Το 1º ελληνικό app διαχείρισης θεμάτων κατασκήνωσης",
  about: "Τι κερδίζετε",
  contact: "Επικοινωνία",
  catchphrase: "Ποτέ η γραμματεία της κατασκήνωσης δεν πέτυχε τέτοια αποτελέσματα στην επίλυση κατασκηνωτικών θεμάτων, με το μικρότερο κόστος και το μεγαλύτερο όφελος!",
  bullet1: "Μειώστε το κόστος σας",
  bullet2: "Αυξήστε την απόδοση της επικοινωνίας",
  bullet3: "Βελτιώστε τα αποτελέσματα",
  bullet4: "Ενδυναμώστε το brand name σας στα μάτια των γονιών που εμπιστεύονται τα παιδιά τους στην κατασκήνωσή σας",
  theProductTitle: "Τι κερδίζετε",
  theGoalTitle: "Σκοπός του Xcamp app",
  theGoalLine1: "Η επικοινωνία της γραμματείας με τους κοινοτάρχες αλλά και με κάποια ειδικά στελέχη αλλάζει.",
  theGoalLine2: "Πλέον δεν γίνεται μέσω τηλεφώνου αλλά με την χρήση του Xcamp app. ",
  theGoalLine3: "Μέσω του Xcamp app θα μπορεί τόσο η γραμματεία όσο και το στέλεχος που του απευθύνεται να διεκπεραιώνουν γρηγορότερα κάθε θέμα που προκύπτει αλλά και να έχουν και το ανάλογο feedback. ",
  theGoalLine4: "Παράλληλα θα μπορεί να είναι ενήμερος (αναλόγως και της σοβαρότητας του κάθε θέματος) και ο αντίστοιχος αρμόδιος βάσει της ιεραρχίας (π.χ. ο αρχηγός, το ιατρείο, η διοίκηση κ.λ.π). Η φιλοσοφία του app είναι απλή και φιλική.",
  theUseTitle: "Η λειτουργία του",
  theUse: "Η κεντρική ιδέα είναι ότι κάθε επικοινωνία των γονέων προς την κατασκήνωση περνάει από την γραμματεία. Στην συνέχεια η γραμματεία στέλνει το κάθε θέμα που προκύπτει στον αρμόδιο μέσω του app και ο αρμόδιος ειδοποιείται μέσω ενός Push notification. Αφού το διαβάσει καλείται να το διεκπεραιώσει και αφού γίνεται αυτό, το αναφέρει και έτσι κλείνει ο κύκλος του.",
  theProductFirstLine: "Η εφαρμογή αυτή έχει σκοπό να βελτιώσει την επικοινωνία ανάμεσα στη γραμματεία, τους ομαδάρχες και τα μέλη μιας κατασκήνωσης, με στόχο την εξοικονόμηση χρόνου και χρημάτων.",
  theProduct: " Ένα σημαντικό σημείο της εφαρμογής είναι ότι η γραμματεία μπορεί να επικοινωνήσει με τους ομαδάρχες μέσω της εφαρμογής και να αποφύγει τα τηλεφωνήματα και τα μηνύματα μπρος-πίσω, με αποτέλεσμα να μην χρειάζεται ένα άτομο να ασχολείται με αυτά όλη την ημέρα. ",
  theProduct2: " Η εφαρμογή βοηθάει στην ταχύτερη επίλυση των προβλημάτων καθώς καταγράφονται τα ζητήματα που προκύπτουν σε chat και οι ομαδάρχες και η γραμματεία έχουν άμεση πρόσβαση σε αυτά. Αυτό αποτελεί ένα θετικό στοιχείο για την κατασκήνωση καθώς αυξάνει το βαθμό αξιοπιστίας του ονόματός της και εμφανίζει μια δομημένη και συγκροτημένη γραμματεία απέναντι στα μάτια των γονιών. ",
  more: "Περισσότερα",
  less: "Λιγότερα",
  featuresTitle: "Χαρακτηριστικά της Εφαρμογής",
  feature0Title: "Ειδοποιήσεις",
  feature0: "Ο κάθε χρήστης λαμβάνει στο Native App ειδοποίηση στην οθόνη του για γρήγορη ενημέρωση.",
  feature1Title: "Προτεραιότητα ζητημάτων",
  feature1: "Η ιεράρχηση των ζητημάτων (low, medium, high, immediate) δίνει τη δυνατότητα στα διάφορα στελέχη της κατασκήνωσης να επικεντρωθούν στις βασικές εργασίες.",
  feature2Title: "Κατάσταση ζητημάτων",
  feature2: "Ο χρήστης μπορεί να ορίσει σε ποια κατάσταση βρίσκεται ένα ζήτημα (open, resolved, closed).",
  feature3Title: "Κατηγοριοποίηση ζητημάτων",
  feature3: "Τα ζητήματα κατηγοριοποιούνται με βάση την προτεραιότητα τους, την κατάσταση τους, καθώς και με βάση την ομάδα που τα έχει εκδώσει.",
  feature4Title: "Αναζήτηση ζητημάτων",
  feature4: "Ο χρήστης έχει τη δυνατότητα αναζήτησης κάποιου ζητήματος.",
  feature5Title: "Καταγραφή ζητημάτων",
  feature5: "Όλα τα ζητήματα καταγράφονται έτσι ώστε να παρέχεται στον χρήστη μια συνολική εικόνα των ζητημάτων της κατασκήνωσης.",
  feature6Title: "Δημιουργία ομάδων",
  feature6: "Ανάλογα με τις απαιτήσεις της δικής σας κατασκήνωσης, μπορείτε να δημιουργήσετε τις αντίστοιχες ομάδες.",
  feature7Title: "Εξαγωγή σε excel όλων των δεδομένων",
  feature7: "Η γραμματεία σας έχει τη δυνατότητα να κάνει εξαγωγή σε excel των δεδομένων που καταγράφηκαν σε κάθε περίοδο.",
  feature8Title: "Απολεσθέντα",
  feature8: "Η γραμματεία μπορεί εύκολα και γρήγορα να διαχειριστεί ζητήματα απολεσθέντων αντικειμένων.",
  formTitle: "Επικοινωνήστε μαζί μας για να σας παρουσιάσουμε την εφαρμογή και να λύσουμε τις απορίες σας",
  formSubtitle: "Στείλτε μας το όνομα και το τηλέφωνο σας στο ",
  q1: "Τι είναι το Xcamp;",
  a1: "Το Xcamp είναι μια φιλική προς το χρήστη εφαρμογή που αναπτύχθηκε ειδικά για να βελτιστοποιήσει την επικοινωνία μεταξύ των μελών μιας κατασκήνωσης.",
  q2: "Σε ποιους απευθύνεται;",
  a2: "Η εφαρμογή απευθύνεται στα μέλη μιας κατασκήνωσης, όπως η γραμματεία, οι ομαδάρχες, τα στελέχη και οι διάφορες ομάδες της.",
  q3: "Γιατί να το χρησιμοποιήσω;",
  a3: "Το Xcamp αποτελεί μια εξαιρετική λύση για την διευκόλυνση στην καθημερινή επικοινωνία των μελών μιας κατασκήνωσης, παρέχει μια απλή και εύχρηστη διεπαφή, ενώ ταυτόχρονα συμβάλλει στη μείωση του κόστους.",
  q4: "Πόσο εύκολο είναι στη χρήση του;",
  a4: "Ανοίγοντας την εφαρμογή, οι χρήστες καλωσορίζονται με μια καθαρή και οπτικά ευχάριστη αρχική οθόνη. Η πλοήγηση εντός της εφαρμογής είναι απλή, με σαφείς καρτέλες και κουμπιά για διαφορετικές λειτουργίες. Εμφανίζει τα τρέχοντα ζητήματα που έχουν προκύψει, σε τι κατάσταση βρίσκονται, ποιος τα έχει εκδώσει καθώς και για ποιον προορίζονται για να λυθούν. Περιλαμβάνει επίσης πρόσθετες λειτουργίες για τη βελτίωση της εμπειρίας χρήστη. Για παράδειγμα ένας χρήστης μπορεί να στείλει φωτογραφίες έτσι ώστε να παρέχει περισσότερη σαφήνεια και λεπτομέρειες για το ζήτημα προς επίλυση.",
  q5: "Τι χρειάζεται για να ανοίξω έναν λογαριασμό;",
  a5: "Μπορείτε να επικοινωνήσετε μαζί μας στο info@conferience.com",
  terms: "Όροι Χρήσης",
  privacy: "Πολιτική ιδιωτικότητας",
  poweredBy: "Το Xcamp υποστηρίζεται από την",
  testimonialsTitle: "Τι λένε οι πελάτες μας",
  sportcampTestimonialText: "Σας ευχαριστούμε πολύ! Ακούσατε προσεκτικά τις ανάγκες μας και μας παραδώσατε ένα εργαλείο που συνέβαλε τόσο στη βελτίωση της ποιότητας των υπηρεσιών που προσφέραμε στους πελάτες μας, όσο και στην διευκόλυνση και παραγωγικότητα των στελεχών μας. Ήσασταν κοντά μας σε κάθε βήμα, για τυχόν απορίες μας, διορθώσεις, βελτιστοποιήσεις!  Κάθε συνάντηση μας ήταν ουσιαστική χωρίς να μας σπαταλήσετε άδικα ούτε ένα λεπτό. Άμεσοι και αποτελεσματικοί, επεξηγηματικοί και υπομονετικοί. Ομάδα, χαρήκαμε πολύ τη συνεργασία μαζί σας και θέλουμε να συνεχίσουμε με νέα projects!",
  sportcampTestimonialClient: "Αναστασία Μπούκα - Διευθύντρια Αθλητικής Παιδικής κατασκήνωσης SPORTCAMP",
  theranchTestimonialText: "Ένα μεγάλο ευχαριστώ για το τελικό αποτέλεσμα το οποίο ξεπέρασε κάθε προσδοκία. Η αρχική σύλληψη της ιδέας, η υλοποίηση, η συνεργασία για την στοχευμένη βελτίωση της, η άμεση απόκριση και διόρθωση κάθε Bug, όλα λειτούργησαν τέλεια γιατί η ομάδα σας ήταν εκεί κάθε στιγμή που χρειάστηκε! Εντυπωσιασμένοι από τον επαγγελματισμό και την κατάρτιση της ομάδας σας ανυπομονούμε για την συνέχιση της συνεργασίας μας!",
  theranchTestimonialClient: "Δημήτρης Σκούρας - Αντιπρόεδρος κατασκήνωσης The Ranch",
};
const transEn = {
  features: "Features",
  theFirstApp: "The 1º App for issue tracking and communication of the camp administration with your executives",
  about: "What you achieve",
  contact: "Contact",
  catchphrase: "Never before has the camp administration achieved such results with the least cost and the greatest benefit of solving everyday problems with its campers!",
  bullet1: "Reduce your costs",
  bullet2: "Increase communication efficiency",
  bullet3: "Improve results",
  bullet4: "Strengthen your brand name in the eyes of parents who trust their children in your camp",
  theProductTitle: "What you achieve",
  theGoalTitle: "The purpose of the Xcamp app",
  theGoalLine1: "The communication of the camp administration with the community leaders is changing.",
  theGoalLine2: "Now it is no longer done by phone but by using the Xcamp app.",
  theGoalLine3: "Through the Xcamp app, both the camp office and the staff addressed to it will be able to process any issue that arises faster and also have the corresponding feedback.",
  theGoalLine4: "At the same time (depending on the seriousness of each issue) the corresponding person in charge based on the hierarchy (e.g. the leader, the doctor's office, the administration, etc.) will be able to be informed. The philosophy of the app is simple and friendly.",
  theUseTitle: "Its use",
  theUse: "The central idea is that all communication from parents to the camp goes through the camp office. The camp office then sends each issue that arises to the person in charge via the app and the person in charge is notified via a Push notification. After reading it, they are asked to process it and after this is done, they report it and thus closes it's cycle.",
  theProductFirstLine: "The app aims to improve communication between the camp administration, team leaders and members of a camp, with the goal of saving time and money.",
  theProduct: " An important point of the application is that the camp administration can communicate with the team leaders through the application and avoid phone calls and messages back and forth, so that one person does not have to deal with them throughout the day. ",
  theProduct2: "The application helps to solve problems faster as the issues that arise in chat are recorded and team leaders and the camp office have immediate access to them. This is a positive element for the camp as it increases the degree of credibility of its name and displays a structured and organized camp administration in the eyes of parents.",
  more: "More",
  less: "Less",
  featuresTitle: "App Features",
  feature0Title: "Notifications",
  feature0: "In the Native App, each user receives a notification on their screen for a quick update.",
  feature1Title: "Issue prioritization",
  feature1: "The prioritization of the issues (low, medium, high, immediate) enables the various camp staff to focus on the core tasks.",
  feature2Title: "Issue Status",
  feature2: "The user can define what state an issue is in (open, resolved, closed).",
  feature3Title: "Issue Categorization",
  feature3: "Issues are categorized by priority, status, and the team that issued them.",
  feature4Title: "Issue Search",
  feature4: "The user has the ability to search for an issue.",
  feature5Title: "Record of Issues",
  feature5: "All issues are recorded to provide the user with an overall picture of camp issues.",
  feature6Title: "Create teams",
  feature6: "Depending on the requirements of your own camp, you can create the respective groups.",
  feature7Title: "Export everything to Excel",
  feature7: "All the issues created in a camp session can be conveniently downloaded to an excel file",
  feature8Title: "Lost items",
  feature8: "The camp staff can easily and efficiently handle lost and found issues",
  formTitle: "Contact us to present to you the app and answer your questions.",
  formSubtitle: "Send us your name and phone number to ",
  q1: "What is Xcamp?",
  a1: "Xcamp is a user-friendly application developed specifically to optimize communication between members of a camp.",
  q2: "what is the target audience?",
  a2: "The application is aimed at the members of a camp, such as the camp administration, team leaders, executives and its various teams.",
  q3: "Why use it?",
  a3: "Xcamp is an excellent solution for facilitating the daily communication of camp members, providing a simple and easy-to-use interface, while at the same time helping to reduce costs.",
  q4: "How easy is it to use?",
  a4: "Upon opening the app, users are greeted with a clean and visually appealing home screen. Navigation within the app is simple, with clear tabs and buttons for different functions. It shows the current issues that have arisen, what state they are in, who issued them, and who they are intended to be resolved for. It also includes additional features to improve the user experience. For example a user can send photos to provide more details about the issue to be resolved.",
  q5: "What do I need to open an account?",
  a5: "You can contact us at  info@conferience.com",
  terms: "Terms",
  privacy: "Privacy policy",
  poweredBy: "Xcamp is powered by",
  testimonialsTitle: "Testimonials",
  sportcampTestimonialText: "Thank you very much! You listened carefully to our needs and delivered us a tool that contributed both to the improvement of the quality of services we offered to our customers and to the convenience and productivity of our executives. You were with us every step of the way, for any questions, corrections, optimizations! Every meeting was meaningful without you wasting us a single minute. Prompt and efficient, explanatory and patient. Team, we really enjoyed working with you and we want to continue with new projects!",
  sportcampTestimonialClient: "Anastasia Buka - Director of Children's Sports Camp SPORTCAMP",
  theranchTestimonialText: "A big thank you for the final result which exceeded all expectations. The initial conception of the idea, the implementation, the collaboration for its targeted improvement, the immediate response and correction of every Bug, everything worked perfectly because your team was there every moment it was needed! Impressed by the professionalism and training of your team, we look forward to our continued cooperation!",
  theranchTestimonialClient: "Dimitris Skouras - Vice President for The Ranch",
};

const transEs = {
  features: "Funciones",
  theFirstApp: "La 1º App para comunicación y reporte de la administración con tus ejecutivos",
  about: "lo que logras",
  contact: "Contacto",
  catchphrase: "¡Nunca antes, la administración del campamento había logrado resultados tan excepcionales, ofreciendo soluciones eficientes a un costo mínimo para atender las necesidades diarias de nuestros campistas!",
  bullet1: "Reducción de Costos",
  bullet2: "Mayor Eficiencia en la Comunicación",
  bullet3: "Mejora en los Resultados",
  bullet4: "Fortalecimiento de tu Marca ante los Padres que confían en nosotros con sus hijos en el campamento",
  theProductTitle: "lo que logras",
  theGoalTitle: "El propósito de la aplicación Xcamp",
  theGoalLine1: "La forma en que la administración se comunica con los líderes comunitarios está evolucionando.",
  theGoalLine2: "Ya no dependemos del teléfono; en su lugar, utilizamos la aplicación Xcamp.",
  theGoalLine3: "A través de esta plataforma, tanto la administración como su personal pueden gestionar rápidamente cualquier asunto que surja y obtener el feedback correspondiente.",
  theGoalLine4: "Además, según la gravedad de cada tema, podemos informar de manera eficiente al responsable correspondiente según la jerarquía, ya sea el líder, el consultorio médico, la administración, entre otros. La filosofía de nuestra aplicación es simple y amigable.",
  theUseTitle: "Su uso",
  theUse: "La idea central de esta aplicación es que cualquier comunicación de los padres con el campamento pase a través de la administración. Posteriormente, la administración envía cada problema que surja hacia el responsable correspondiente mediante la aplicación, y el responsable recibe una notificación Push al respecto. Después de leer el problema, se le solicita procesarlo, y una vez completado, se cierra el ciclo mediante un informe.",
  theProductFirstLine: "El objetivo principal de la aplicación es mejorar la comunicación entre la administración, los líderes de equipo y los miembros del campamento, con la finalidad de optimizar tanto el tiempo como los recursos económicos.",
  theProduct: "Un aspecto fundamental de la aplicación es que la administración puede comunicarse con los líderes de equipo directamente a través de la plataforma, eliminando la necesidad de llamadas telefónicas y mensajes de ida y vuelta. Esto evita que una sola persona tenga que hacer frente a estas comunicaciones durante todo el día.",
  theProduct2: "La aplicación contribuye a resolver los problemas de manera más rápida, ya que todas las conversaciones y cuestiones que surgen en el chat quedan registradas y son de fácil acceso para los líderes de equipo y la administración. Este enfoque positivo fortalece la reputación del campamento, generando confianza en los padres al mostrar una la administración estructurada y altamente organizada.",
  more: "Más",
  less: "Menos",
  featuresTitle: "Características de la aplicación",
  feature0Title: "Notificaciones",
  feature0: "En la aplicación, cada usuario recibe notificaciones en tiempo real en su pantalla para mantenerse al tanto de las actualizaciones de manera rápida y eficiente.",
  feature1Title: "Priorización de problemas",
  feature1: "La aplicación permite priorizar los problemas en diferentes niveles (baja, media, alta, inmediata), lo que facilita que el personal del campamento se enfoque en las tareas más críticas.",
  feature2Title: "Estado del problema",
  feature2: "Los usuarios pueden definir el estado de un problema, ya sea \"abierto,\" \"resuelto,\" o \"cerrado,\" para mantener un seguimiento claro de su progreso.",
  feature3Title: "Categorización de problemas",
  feature3: "Los problemas se clasifican según su prioridad, estado y el equipo que los reportó, proporcionando una organización efectiva.",
  feature4Title: "Búsqueda de problemas",
  feature4: "Los usuarios tienen la capacidad de buscar problemas de manera sencilla, agilizando la localización de información relevante.",
  feature5Title: "Registro de problemas",
  feature5: "Todos los problemas se registran en la aplicación, brindando a los usuarios una visión general completa de los asuntos del campamento.",
  feature6Title: "Crear equipos",
  feature6: "La aplicación permite la creación de equipos según las necesidades específicas de tu campamento, lo que facilita la colaboración y la asignación de responsabilidades.",
  feature7Title: "Exportación a Excel",
  feature7: "Todos los problemas registrados durante una sesión de campamento pueden descargarse cómodamente en un archivo de Excel para un análisis más detallado y la generación de informes.",
  feature8Title: "Gestión de Objetos Perdidos",
  feature8: "El personal del campamento puede abordar de manera eficiente los problemas relacionados con objetos perdidos y encontrados, garantizando una atención adecuada a los campistas.",
  formTitle: "Contáctanos para presentarte la aplicación y responder tus preguntas.",
  formSubtitle: "Envíanos tu nombre y número de teléfono a ",
  q1: "¿Qué es Xcamp?",
  a1: "Xcamp es una aplicación diseñada para simplificar la comunicación entre los miembros de un campamento, ofreciendo una solución eficiente y fácil de usar.",
  q2: "¿Cuál es el público objetivo?",
  a2: "Xcamp está dirigido a todos los miembros de un campamento, incluyendo la administración, líderes de equipo, ejecutivos y sus respectivos equipos.",
  q3: "¿Por qué usarlo?",
  a3: "Xcamp ofrece una solución excelente para simplificar la comunicación diaria entre los miembros del campamento. Además, proporciona una interfaz simple y amigable, al mismo tiempo que contribuye a reducir costos operativos.",
  q4: "¿Qué tan fácil es de usar?",
  a4: "Al abrir la aplicación, los usuarios son recibidos con una pantalla de inicio limpia y visualmente atractiva. La navegación dentro de la aplicación es sencilla, con pestañas y botones claros para diferentes funciones. Muestra los problemas actuales que han surgido, en qué estado se encuentran, quién los emitió y para quién deben resolverse. También incluye funciones adicionales para mejorar la experiencia del usuario. Por ejemplo, un usuario puede enviar fotografías para brindar más detalles sobre el problema a resolver.",
  q5: "¿Qué necesito para abrir una cuenta?",
  a5: "Puede contactar con nosotros en info@conferience.com",
  terms: "Condiciones de uso",
  privacy: "Política de privacidad",
  poweredBy: "Xcamp es una aplicación de",
  testimonialsTitle: "Testimonios",
  sportcampTestimonialText: "¡Muchas gracias! Escuchó atentamente nuestras necesidades y nos entregó una herramienta que contribuyó tanto a la mejora de la calidad de los servicios que ofrecimos a nuestros clientes como a la comodidad y productividad de nuestros ejecutivos. ¡Estuviste con nosotros en cada paso del camino, para cualquier pregunta, corrección, optimización! Cada reunión fue significativa sin que nos hicieras perder ni un minuto. Rápido y eficiente, explicativo y paciente. Equipo, ¡disfrutamos mucho trabajando con ustedes y queremos continuar con nuevos proyectos!",
  sportcampTestimonialClient: "Anastasia Buka - Director del Campamento Deportivo Infantil SPORTCAMP",
  theranchTestimonialText: "Muchas gracias por el resultado final que superó todas las expectativas. La concepción inicial de la idea, la implementación, la colaboración para su mejora específica, la respuesta inmediata y la corrección de cada error, ¡todo funcionó perfectamente porque su equipo estuvo allí en cada momento que fue necesario! Impresionados por el profesionalismo y la capacitación de su equipo, ¡esperamos continuar nuestra cooperación!",
  theranchTestimonialClient: "Dimitris Skouras - Vicepresidente de The Ranch",
};

const transRu = {
  features: "Особенности",
  theFirstApp: "Это первое приложение для отслеживания проблем и коммуникации администрации лагеря с вашими руководителями.",
  about: "Чего вы достигните",
  contact: "Контакт",
  catchphrase: "Никогда прежде администрация лагеря не добивалась таких результатов с минимальными затратами и максимальной пользой при решении повседневных проблем с участниками лагеря!",
  bullet1: "Сократите свои расходы",
  bullet2: "Повысите эффективность коммуникации",
  bullet3: "Улучшите результаты",
  bullet4: "Укрепите свой бренд в глазах родителей, которые доверяют вам своих детей лагере",
  theProductTitle: "Чего вы достигните",
  theGoalTitle: "Цель приложения Xcamp",
  theGoalLine1: "Изменяется коммуникация администрации лагеря с руководителями команд.",
  theGoalLine2: "Теперь это больше не делается по телефону, а при помощи приложения Xcamp.",
  theGoalLine3: "При помощи приложения Xcamp как офис лагеря, так и сотрудники, которым оно адресовано, смогут быстрее решить любую возникающую проблему, а также получить соответствующую обратную связь.",
  theGoalLine4: "At the same time В то же время (в зависимости от серьезности каждой проблемы) ответственное лицо в соответствии с иерархией (например, руководитель, кабинет врача, администрация и т.д.) сможет быть проинформировано. Философия приложения проста и дружелюбна.",
  theUseTitle: "Его использование",
  theUse: "Основная идея заключается в том, что все общение родителей с лагерем проходит через офис лагеря. Затем офис лагеря отправляет каждую возникающую проблему ответственному лицу при помощи приложения, и ответственное лицо получает уведомление с помощью Push-уведомления. После того, как ответственное лицо прочтет сообщение, ему нужно его обработать, и после завершения этой обработки ответственное лицо выполняет доклад, тем самым завершает цикл сообщения.",
  theProductFirstLine: "Приложение направлено на улучшение коммуникации между администрацией лагеря, руководителями команд и членами лагеря с целью экономии времени и денег.",
  theProduct: "Важным моментом приложения является то, что администрация лагеря может общаться с руководителями команд при помощи приложения и избегать обратных звонков и сообщений, таким образом, чтобы один человек не должен был бы иметь с ними дело на протяжении всего дня.",
  theProduct2: "Приложение помогает решать проблемы быстрее, так как проблемы, возникающие в чате, регистрируются, и у руководителей команд и лагерного офиса есть мгновенный доступ к ним. Это положительный элемент для лагеря, поскольку повышает степень доверия к его названию и показывает родителям структурированную и организованную администрацию лагеря.",
  more: "More",
  less: "Less",
  featuresTitle: "Особенности приложения",
  feature0Title: "Уведомления",
  feature0: "В собственном приложении каждый пользователь получает уведомление на своем экране о быстром обновлении.",
  feature1Title: "Определение приоритетов проблем",
  feature1: "Определение приоритетов проблем (низкий, средний, высокий, неотложный) позволяет различным сотрудникам лагеря сосредоточиться на основных задачах.",
  feature2Title: "Статус проблемы",
  feature2: "Пользователь может определить, в каком состоянии находится проблема (открыта, решена, закрыта).",
  feature3Title: "Классификация проблем",
  feature3: "Проблемы классифицируются по приоритету, статусу и команде, которая их инициировала.",
  feature4Title: "Поиск проблемы",
  feature4: "У пользователя есть возможность выполнить поиск проблемы.",
  feature5Title: "Отчет о проблемах",
  feature5: "Все проблемы регистрируются, чтобы предоставить пользователю общую картину проблем лагеря.",
  feature6Title: "Создать команды",
  feature6: "В зависимости от потребностей вашего собственного лагеря вы можете создать соответствующие группы.",
  feature7Title: "Экспортируйте все в Excel",
  feature7: "Все вопросы, созданные на тренировочном занятии, можно удобно загрузить в файл Excel",
  feature8Title: "Потерянные предметы",
  feature8: "Персонал лагеря может легко и эффективно управлять утерянными и найденными вещами.",
  formTitle: "Свяжитесь с нами, чтобы мы представили вам приложение и ответили на ваши вопросы.",
  formSubtitle: "Пришлите нам свое имя и номер телефона по адресу ",
  q1: "Что такое Xcamp?",
  a1: "Xcamp - это удобное приложение, разработанное специально для оптимизации общения между участниками лагеря.",
  q2: "Какова целевая аудитория?",
  a2: "Приложение ориентировано на участников лагеря, таких как администрация лагеря, руководители команд, исполнительные сотрудники и различные команды.",
  q3: "Зачем его использовать?",
  a3: "Xcamp - это отличное решение для облегчения ежедневного общения участников лагеря, которое обеспечивает простой и понятный в использовании интерфейс и в то же время помогает снизить затраты.",
  q4: "Насколько оно просто в применении?",
  a4: "При открытии приложения пользователи обнаруживают упорядоченный и визуально привлекательный домашний экран. Навигация в приложении проста, с понятными вкладками и кнопками для различных функций. Приложение показывает текущие возникшие проблемы, в каком состоянии они находятся, кто их инициировал и для кого они предназначены для решения. Приложение также включает в себя дополнительные функции, чтобы улучшить пользовательский опыт.  Например, пользователь может отправить фотографии, чтобы предоставить более подробную информацию о проблеме, которую нужно решить.",
  q5: "Что мне необходимо, чтобы открыть учетную запись?",
  a5: "Вы можете связаться с нами по адресу info@conferience.com",
  terms: "Условия и положения",
  privacy: "Политика конфиденциальности",
  poweredBy: "Xcamp работает на основе",
  testimonialsTitle: "Рекомендации",
  sportcampTestimonialText: "Большое спасибо! Вы внимательно выслушали наши потребности и предоставили нам инструмент, который способствовал как повышению качества услуг, предлагаемых нашим клиентам, так и удобству и производительности наших руководителей. Вы были с нами на каждом этапе, отвечали на любые вопросы, вносили исправления, оптимизировали! Каждая встреча была содержательной, и вы не потратили на нас ни минуты. Оперативный и эффективный, толковый и терпеливый. Команда, нам действительно понравилось работать с вами, и мы хотим продолжить работу над новыми проектами!",
  sportcampTestimonialClient: "Анастасия Бука - директор детского спортивного лагеря SPORTCAMP",
  theranchTestimonialText: "Большое спасибо вам за конечный результат, который превзошел все ожидания. Первоначальная концепция идеи, реализация, сотрудничество для ее целенаправленного улучшения, немедленное реагирование и исправление каждой ошибки - все работало идеально, потому что ваша команда была рядом в любой момент, когда это было необходимо! Впечатлены профессионализмом и подготовкой вашей команды, с нетерпением ждем продолжения нашего сотрудничества!",
  theranchTestimonialClient: "Димитрис Скурас - Вице-президент The Ranch",
};

i18n
  .use(initReactI18next)
  .init({
      resources: {
          gr: { translation: transGr},
          en: { translation: transEn},
          es: { translation: transEs},
          ru: { translation: transRu},
      },
      lng: "en",
      fallbackLng: "gr",
      interpolation: { escapeValue: false },
  });

export default function App() {

  const changeLanguage = (event) => {
      i18n.changeLanguage(event.target.value);
  }

  const [position, setPosition] = useState(0);

  function goToTop(){
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  const handleScroll = () => {
    const currentPosition = window.pageYOffset;
    setPosition(currentPosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <Suspense fallback="Loading...">
    <div className="App">
        {/*  */}
        
      <Navbar />
      <Main />
      <Description />
      <Testimonials />
      <Features />
      <About />
      <Footer />
      {
        position > 0 &&
        <img onClick={goToTop} id="fixed-arrow" src="./images/arrow-up.png" alt="arrow up" />
      }
    </div>
    </Suspense>
  );
}


